<template>
  <b-modal id="modal-add-to-bundle" centered scrollable static lazy hide-header hide-footer>
    <template v-if="bundlingIndex !== null">
      <div class="row no-gutters flex-column h-100">
        <h2 class="col-auto text-center pt-2">Choose Product</h2>
        <div class="col-auto col-lg overflowing">
          <div class="row no-gutters row-cols-2 row-cols-lg-3 flex-nowrap overflow-auto">
            <div class="col px-1" v-for="(choice, i) in product.product_bundle[bundlingIndex].bundle_product" :key="i">
              <div class="bundle-box d-flex flex-column"
                :class="{
                  'selected' : choice.selected,
                  'opacity-1' : choice.stock == 0
                }"
                @click="chooseProduct(product.product_bundle[bundlingIndex].bundle_product, choice)"
              >
                <b-img-lazy :src="choice.product_images[0].medium_image_url" :title="choice.name" :alt="choice.name" fluid @error.native="replaceByDefaultImage" class="flex-grow-1" />
                <b>{{ choice.brand_name }}</b>
                {{ choice.name }} 
                <div class="product-price pt-1" :class="{ 'sale-price': choice.price_display < choice.price }">
                  {{ formatPrice(choice.price_display) }}
                  <span v-if="choice.price_display < choice.price" class="old-price">{{ formatPrice(choice.price) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2" v-for="(selected, i) in product.product_bundle[bundlingIndex].bundle_product.filter(el => el.selected)" :key="i">
            <Accordion title="Product Details">
                Brand: {{selected.brand_name}}<br/>
                Model: {{selected.category_name}}<br/>
                Kadar: {{selected.kadar}}<br/>
                Color: {{groupProductColors(selected).map(el => el.name).join(', ').replace(/--none--/g, '-')}}<br/>
                Appx. Weight (gr): {{selected.weight}}<br/>
                Stamp: {{selected.stamp}}<br/>
                Certificate: {{selected.certificate}}
            </Accordion>
            <Accordion v-if="selected.description" title="Product Description" :body="selected.description" />
            <div class="row align-items-center mt-3" v-if="selected.category_name.indexOf('Fine') < 0">
              <div class="col-md-2">
                <label class="mb-0">Color</label>
              </div>
              <div class="col pl-lg-0">
                <label class="radio-container mb-0" v-for="(color, j) in groupProductColors(selected)" :key="`color-${j}`">
                  <input type="radio" name="radio" :value="color.id" v-model="selected.selectedColor" @click="chooseColor(selected, color.id); chooseVariant()" />
                  <span class="checkmark overflow-hidden" style="color: black" :style="{'--background': color.color}">
                    {{ color.name.split(' ').length == 1
                      ? color.name.charAt(0) != '-' ? color.name : '-'
                      : color.name.split(' ').map(el => el.charAt(0)).join('')
                    }}
                  </span>
                </label>
              </div>
            </div>
            <div class="row align-items-center mt-2" v-if="selected.category_name.indexOf('Ring') >= 0">
              <div class="col-md-2">
                <label class="mb-0">Size</label>
                <fawesome-pro variant="fal" icon="info-circle" class="category-size-info" v-b-modal.modal-category-size v-if="selected.category_size.length" @click.native="$emit('showModalCategorySize', selected)" />
              </div>
              <div class="col pl-lg-0">
                <select id="chosen-bundle-variance" class="form-control w-auto"
                  v-model="selected.selectedVariant"
                  :disabled="!selected.selectedColor"
                  @change="chooseVariant"
                >
                  <option disabled value="null">Choose {{ selected.selectedColor ? 'Size' : 'a Color First!' }}</option>
                  <option v-for="(variance, j) in selected.product_detail_variance.filter(el => el.color.id == selected.selectedColor)" :key="`size-${j}`" :value="variance.id" :disabled="selected.status_stock && variance.total_stock == 0">
                    {{ variance.size.international.charAt(0) == '-' ? '-' : variance.size.international }} {{ selected.status_stock && variance.total_stock == 0 ? '- Out of Stock' : ''}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <b-button-group>
          <!-- Back Button -->
          <template v-if="bundlingIndex > 0">
            <b-button class="btn-wz-proceed btn-block modal-btn-submit" @click="$emit('previousIndex')">Back</b-button>
            <b-button variant="light" disabled class="btn-group-separator"></b-button> <!-- Button Separator -->
          </template>
          <!-- Next Button -->
          <b-button class="btn-wz-proceed btn-block modal-btn-submit"
            :disabled="product.product_bundle[bundlingIndex] && product.product_bundle[bundlingIndex].bundle_product.filter(el => el.selected && el.selectedVariant).length == 0"
            @click="$emit('nextIndex');" 
            v-if="bundlingIndex < product.product_bundle.length - 1">Next</b-button>
          <template v-if="bundlingIndex == product.product_bundle.length - 1">
            <!-- Order via Whatsapp button for BP product catalog  -->
            <b-button class="btn-wz-buy-now btn-block modal-btn-submit d-flex justify-content-center align-items-center"
              :disabled="product.product_bundle[bundlingIndex] && product.product_bundle[bundlingIndex].bundle_product.filter(el => el.selected && el.selectedVariant).length == 0"
              @click="$emit('orderViaWhatsapp')" 
              v-if="isOrderViaWhatsapp"
            >
              <div>Order Via Whatsapp</div>
            </b-button>
            <!-- Buy Now & Add to Bag Button -->
            <template v-else>
              <b-button class="btn-wz-buy-now btn-block modal-btn-submit d-flex justify-content-center align-items-center"
                :disabled="product.product_bundle[bundlingIndex] && product.product_bundle[bundlingIndex].bundle_product.filter(el => el.selected && el.selectedVariant).length == 0"
                @click="$emit('quickCheckout', product, product.product_detail_variance[0].id , 1)" 
                >
                  <font-awesome icon="spinner" size="2x" spin class="mr-2" v-if="isQuickCheckoutLoading" /> 
                  <div>Buy Now</div>
              </b-button>
              <b-button variant="light" disabled class="btn-group-separator"></b-button> <!-- Button Separator -->
              <b-button class="btn-wz-add-bag btn-block modal-btn-submit d-flex justify-content-center align-items-center" 
                :disabled="product.product_bundle[bundlingIndex] && product.product_bundle[bundlingIndex].bundle_product.filter(el => el.selected && el.selectedVariant).length == 0"
                @click="$emit('addToCart', product, product.product_detail_variance[0].id , 1)" 
                >
                  <font-awesome icon="spinner" size="2x" spin class="mr-2" v-if="isAddCartLoading" />
                  <div>Add to Bag</div>
              </b-button>
            </template>
          </template>
        </b-button-group>
        <!-- <button type="button" class="col-auto btn btn-wz-proceed btn-block modal-btn-submit"
          :disabled="product.product_bundle[bundlingIndex]
            && product.product_bundle[bundlingIndex].bundle_product.filter(el => el.selected && el.selectedVariant).length == 0"
          @click="chooseVariant">Choose Product</button> -->
      </div>
    </template>
  </b-modal>
</template>

<script>
import { toaster } from '@/_helpers';
const Accordion = () => import('@/components/bundling/Accordion');

export default {
  name: 'ModalAddBundle',
  components: {
    Accordion
  },
  props: ['index', 'parent', 'isOrderViaWhatsapp'],
  data() {
    return {
      bundlingIndex: this.index,
      product: this.parent,
      isAddCartLoading: false,
      isQuickCheckoutLoading: false,
    }
  },
  methods: {
    groupProductColors(product) {
      // https://stackoverflow.com/a/54088854
      return [...product.product_detail_variance.map(el => el.color).reduce((a, b) => {
        a.set(b.id, b)
        return a
      }, new Map()).values()]
    },
    chooseProduct(bundle, choice) {
      if (choice.product_detail_variance.length && choice.stock > 0 || !choice.status_stock) {
        bundle.map(el => el.selected = false)
        if (choice.product_detail_variance.length == 1) {
          choice.selectedColor = choice.product_detail_variance[0].color.id
          choice.selectedVariant = choice.product_detail_variance[0].id
          this.product.product_bundle[this.bundlingIndex].submitted = choice; // Auto select the submitted product
        }
        choice.selected = true
      } else toaster.make("Product is out of stock", "danger")
    },
    chooseColor(choice, colorId) {
      const sizes = choice.product_detail_variance.filter(el => el.color.id == colorId)
      choice.selectedVariant = sizes.length == 1
        ? sizes[0].total_stock > 0 || !choice.status_stock ? sizes[0].id : null
        : null

      if (sizes.length == 1 && sizes[0].total_stock == 0) toaster.make("The variance you selected is out of stock", "danger") 
    },
    chooseVariant() {
      // this.$bvModal.hide('modal-add-to-bundle');
      this.product.product_bundle[this.bundlingIndex].submitted = this.product.product_bundle[this.bundlingIndex].bundle_product.find(el => el.selected && el.selectedVariant)
    },
    formatPrice(value) {
      // return value
      //   ? value.toLocaleString("id", { maximumFractionDigits: 2 })
      //   : value;
      return this.$helpers.format.price(value);
    },
    replaceByDefaultImage(img) {
      img.target.src = require("@/assets/img/product-default.png");
      img.target.classList.add("default-image");
    },
  },
  watch: {
    index(newVal) {
      this.bundlingIndex = newVal
    },
    parent(newVal) {
      this.product = newVal
    },
  }
}
</script>
<style scoped>
.btn-block+.btn-block {
  margin-top: 0;
}
.btn-group-separator {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0 !important;
  min-width: 2px;
}
</style>